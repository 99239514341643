import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import Layout from 'src/components/layout';
import { Modal } from 'src/components/shared/ui';
import { tokenListener } from 'src/helper/firebase';
import { AppRoutes, AppRoutes as RoutesType, RoleRoutes } from 'src/interfaces/routes-types';
import { RootState, useAppSelector } from 'src/redux/store';
import { closeModal } from 'src/redux/ui/actions';
import { AccessRoleType, AppDispatch } from 'src/types';

import styles from '../components/layout/layout.module.css';
import PrivateRoute from './private-route';

const Login = lazy(() => import('src/components/pages/login'));
const NotAllowed = lazy(() => import('src/components/pages/not-allowed'));
const AdminAd = lazy(() => import('./admin-ad'));
const AdminAl = lazy(() => import('./admin-al'));

const MainRoutes = () => {
  const dispatch: AppDispatch<null> = useDispatch();
  const showModal = useAppSelector((state: RootState) => state.ui.configModal.showModal);
  const [currentRoute, setCurrentRoute] = useState<string>('');

  const { pathname } = useLocation();

  const lsAccessTypes = localStorage.getItem('accessTypes')
    ? localStorage
        .getItem('accessTypes')
        .split(',')
        .map((item) => item.trim())
    : [];

  useEffect(() => {
    tokenListener();
  }, []);

  useEffect(() => {
    setCurrentRoute(pathname);
    if (showModal && pathname !== currentRoute) {
      dispatch(closeModal());
    }
  }, [currentRoute, pathname, showModal]);

  const redirectPath = (accessTypes) => {
    let path = '/login';
    if (accessTypes.includes(AccessRoleType.ACCOUNTANCY_AD)) {
      path = `${RoleRoutes.ACCOUNTANCY_AD}${AppRoutes.DRAFTS}`;
    } else if (accessTypes.includes(AccessRoleType.ACCOUNTANCY_AL)) {
      path = `${RoleRoutes.ACCOUNTANCY_AL}${AppRoutes.LIQUIDATIONS}`;
    }
    return path;
  };

  return (
    <div className={styles.container}>
      <Suspense>
        <Modal testId="uniqueModal" isOpen={showModal} onClose={() => dispatch(closeModal())} />
        <Routes>
          <Route element={<Layout />}>
            <Route path={RoutesType.LOGIN} element={<Login />} />
            <Route
              path={`${RoleRoutes.ACCOUNTANCY_AL}/*`}
              element={
                <PrivateRoute privateRouteAccessTypes={[AccessRoleType.ACCOUNTANCY_AL]}>
                  <AdminAl />
                </PrivateRoute>
              }
            />
            <Route
              path={`${RoleRoutes.ACCOUNTANCY_AD}/*`}
              element={
                <PrivateRoute privateRouteAccessTypes={[AccessRoleType.ACCOUNTANCY_AD]}>
                  <AdminAd />
                </PrivateRoute>
              }
            />
            <Route path={RoutesType.NOT_ALLOWED} element={<NotAllowed />} />
            <Route path="/*" element={<Navigate to={redirectPath(lsAccessTypes)} />} />
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
};

export default MainRoutes;
