import React from 'react';
import { useDispatch } from 'react-redux';
import { LinearProgress } from '@mui/material';

import { Variant } from 'src/components/shared/ui/buttons/button/types';
import { closeModal } from 'src/redux/ui/actions';
import { AppDispatch } from 'src/types';

import Button from '../buttons/button';
import styles from './modal.module.css';
import { ConfirmModalProps } from './types';

const ConfirmModal = (props: ConfirmModalProps) => {
  const { title, text, action, isPending, onClose, onCancel } = props;
  const dispatch: AppDispatch<null> = useDispatch();

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <h2>{title}</h2>
        <div className={styles.loaderAndText}>
          {isPending ? (
            <div>
              <LinearProgress />
            </div>
          ) : (
            <p>{text}</p>
          )}
        </div>
      </div>
      <div className={styles.button}>
        <div>
          <Button
            materialVariant={Variant.OUTLINED}
            color="primary"
            label="Cancelar"
            disabled={isPending}
            onClick={
              onCancel ? () => onCancel() : onClose ? () => onClose() : () => dispatch(closeModal())
            }
          />
        </div>
        <div>
          <Button
            materialVariant={Variant.CONTAINED}
            color="primary"
            label="Aceptar"
            disabled={isPending}
            onClick={() => {
              action();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
