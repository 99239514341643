import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

import previewStyles from './preview.module.css';
import { PreviewProps } from './types';

const Preview = (props: PreviewProps) => {
  const { data, split } = props;

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4',
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    containerMain: {
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      display: 'flex',
      flexDirection: split ? 'column' : 'row',
    },
    text: {
      display: 'flex',
      justifyContent: 'center',
      width: split ? '150px' : '100%',
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#373867',
      padding: '6px',
    },
    fontStyle: {
      fontSize: '12px',
    },
    textHeader: {
      width: split ? '150px' : '100%',
      fontSize: '16px',
      fontWeight: 'bold',
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      padding: '6px',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: split ? 'auto' : '45px',
      backgroundColor: '#373867',
      justifyContent: 'center',
      padding: '10px',
    },
    values: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: split ? 'auto' : '30px',
      padding: '10px',
      justifyContent: 'center',
    },
  });

  const splitArray = (array, maxLength) => {
    if (array.length <= maxLength) {
      return [array];
    }

    return array.reduce((result, element) => {
      const lastArray = result[result.length - 1];
      if (!lastArray || lastArray.length === maxLength) {
        result.push([element]);
      } else {
        lastArray.push(element);
      }
      return result;
    }, []);
  };
  const maxLength = 6;

  const dataToPrint = splitArray(data, maxLength);

  return (
    <div className={previewStyles.container}>
      {split ? (
        dataToPrint.map((data) => {
          return (
            <div key={data}>
              <View style={styles.header}>
                {data.map((header) => {
                  return (
                    <View style={styles.textHeader} key={header}>
                      <Text style={styles.fontStyle}>{header.header}</Text>
                    </View>
                  );
                })}
              </View>
              <View style={styles.values}>
                {data.map((value) => {
                  return (
                    <View style={styles.text} key={value}>
                      <Text style={styles.fontStyle}>{value.value}</Text>
                    </View>
                  );
                })}
              </View>
            </div>
          );
        })
      ) : (
        <>
          {data.map((preview) => {
            return (
              <div className={previewStyles.containerMain} key={preview.header}>
                <div className={previewStyles.header}>
                  <div className={previewStyles.textHeader}>
                    <p className={previewStyles.fontStyle}>{preview.header}</p>
                  </div>
                </div>
                <div className={previewStyles.values}>
                  <div key={preview.value} className={previewStyles.text}>
                    <p className={previewStyles.fontStyle}>{preview.value}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default Preview;
