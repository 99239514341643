import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from 'src/config/api';

import { Heading } from './types';

export const useUpdateHeadingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['updateHeading'],
    mutationFn: (options: { id: string; data: Partial<Heading> }) => {
      return api.put(`heading/${options.id}`, options.data);
    },

    onSuccess: async () =>
      await queryClient.invalidateQueries({
        queryKey: ['headings'],
      }),
  });
};

export const usePostHeadingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['postHeading'],
    mutationFn: (data: Partial<Heading>) => {
      return api.post('heading/', data);
    },

    onSuccess: async () =>
      await queryClient.invalidateQueries({
        queryKey: ['headings'],
      }),
  });
};

export const useDeleteHeadingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteHeading'],
    mutationFn: (id: string) => {
      return api.delete(`heading/delete/${id}`);
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['headings'],
      });
    },
  });
};

export const useActivateHeadingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['activateHeading'],
    mutationFn: (id: string) => {
      return api.patch(`heading/activate/${id}`);
    },

    onSuccess: async () =>
      await queryClient.invalidateQueries({
        queryKey: ['headings'],
      }),
  });
};

export const useDisableHeadingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['disableHeading'],
    mutationFn: (id: string) => {
      return api.patch(`heading/disable/${id}`);
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['headings'],
      });
    },
  });
};
