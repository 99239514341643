import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from 'src/config/api';
import store from 'src/redux/store';
import { closeModal } from 'src/redux/ui/actions';

import { Employee } from './types';

export const useEmployeeCategoryMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['employee-category'],
    mutationFn: (options: { id: string; data: Partial<Employee> }) => {
      return api.patch(`employees/category/${options.id}`, options.data);
    },
    onSuccess: async () => {
      store.dispatch(closeModal());
      await queryClient.invalidateQueries({
        queryKey: ['employees'],
      });
    },
    onError: (error) => {
      console.error(error);
    },
  });
};
