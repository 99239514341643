import React from 'react';
import { SvgIcon } from '@mui/material';

const WarningIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="25"
        height="25"
        viewBox="0 0 35 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.503906 27.4584H33.5944L17.0492 0.541748L0.503906 27.4584ZM18.5533 23.2084H15.5451V20.3751H18.5533V23.2084ZM18.5533 17.5417H15.5451V11.8751H18.5533V17.5417Z"
          fill="#F13312"
        />
      </svg>
    </SvgIcon>
  );
};

export default WarningIcon;
