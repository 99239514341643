import { combineReducers } from 'redux';

import authReducer from '../auth/reducer';
import draftsReducer from '../drafts/reducer';
import uiReducer from '../ui/reducer';

const rootReducer = combineReducers({
  drafts: draftsReducer,
  ui: uiReducer,
  auth: authReducer,
});

export default rootReducer;
