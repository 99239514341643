import React from 'react';

import styles from './loading.module.css';
import { LoadingProps } from './types';

const Loading = (props: LoadingProps) => {
  return (
    props.show && (
      <div className={styles.modalOverlay}>
        <div className={styles.loader}></div>
      </div>
    )
  );
};

export default Loading;
