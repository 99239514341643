import { AppRoutes } from 'src/interfaces/routes-types';
import { AccessRoleType } from 'src/types';

export const navbarItems = [
  {
    path: AppRoutes.LIQUIDATIONS,
    name: 'LIQUIDACIONES',
    navAccessTypes: [AccessRoleType.ACCOUNTANCY_AL],
  },
  { path: AppRoutes.DRAFTS, name: 'DRAFTS', navAccessTypes: [AccessRoleType.ACCOUNTANCY_AD] },
  {
    path: AppRoutes.HISTORIES,
    name: 'HISTÓRICO',
    navAccessTypes: [AccessRoleType.ACCOUNTANCY_AL, AccessRoleType.ACCOUNTANCY_AD],
  },
  { path: AppRoutes.HEADINGS, name: 'RUBROS', navAccessTypes: [AccessRoleType.ACCOUNTANCY_AL] },
  { path: AppRoutes.EMPLOYEES, name: 'EMPLEADOS', navAccessTypes: [AccessRoleType.ACCOUNTANCY_AL] },
];

export const operationItems = {
  SUM: 1,
  REST: -1,
};
