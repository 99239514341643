import { CategoryData } from '../employees/types';

export enum CurrencyType {
  USD = 'USD',
  ARS = 'ARS',
  UY = 'UY',
}

export enum OperationType {
  SUM = 'SUM',
  REST = 'REST',
}
export interface Heading {
  _id?: string;
  name: string;
  description?: string;
  onlyLiquidate: boolean;
  categories: CategoryData[];
  operation: OperationType;
  currency: CurrencyType;
  isActive: boolean;
  isDeleted: boolean;
}
