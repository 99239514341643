import { Category } from 'src/services/categories/types';
import { Heading } from 'src/services/headings/types';

export interface FormValues {
  _id?: string;
  name: string;
  description?: string;
  onlyLiquidate: boolean;
  categories: Category[] | CategoryInput[];
  operation: OperationType;
  currency: CurrencyType;
  isActive: boolean;
}

export enum OperationType {
  SUM = 'SUM',
  REST = 'REST',
}

export enum CurrencyType {
  USD = 'USD',
  ARS = 'ARS',
  UY = 'UY',
}

export interface CategoryInput {
  id: string;
  label: string;
}
export interface HeadingFormModalProps {
  id?: string;
  title?: string;
  heading?: Heading | Record<string, never>;
  onClose?: () => void;
}
