import React from 'react';
import { Dialog as MaterialModal } from '@mui/material';

import EmployeeForm from 'src/components/pages/employees/employee-form';
import { EmployeeFormModalProps } from 'src/components/pages/employees/employee-form/types';
import HeadingForm from 'src/components/pages/headings/heading-form';
import { HeadingFormModalProps } from 'src/components/pages/headings/heading-form/types';
import { RootState, useAppSelector } from 'src/redux/store';
import { Contain } from 'src/redux/ui/types';

import AlertModal from '../alert-modal';
import { AlertModalProps } from '../alert-modal/types';
import ConfirmModal from '../confirm-modal';
import { ConfirmModalProps } from '../confirm-modal/types';
import Pdf from '../pdf';
import { PdfProps } from '../pdf/types';
import Preview from '../preview';
import { PreviewProps } from '../preview/types';
import styles from './modal.module.css';
import { ModalProps } from './types';

const Modal = (props: ModalProps): JSX.Element => {
  const { onClose, testId, isOpen } = props;
  const showModal = useAppSelector((state: RootState) => state.ui.configModal.contain);
  const propsModal = useAppSelector((state: RootState) => state.ui.configModal.props);

  const renderedComponent = () => {
    switch (showModal) {
      case Contain.ALERT_MODAL:
        return <AlertModal {...(propsModal as AlertModalProps)} />;
      case Contain.HEADING_FORM_MODAL:
        return <HeadingForm {...(propsModal as HeadingFormModalProps)} />;
      case Contain.EMPLOYEE_FORM_MODAL:
        return <EmployeeForm {...(propsModal as EmployeeFormModalProps)} />;
      case Contain.CONFIRM_MODAL:
        return <ConfirmModal {...(propsModal as ConfirmModalProps)} />;
      case Contain.PREVIEW_MODAL:
        return <Preview {...(propsModal as PreviewProps)} />;
      case Contain.PDF_MODAL:
        return <Pdf {...(propsModal as PdfProps)} />;
      default:
        return <></>;
    }
  };

  const handleClose = (_, reason) => {
    reason === 'backdropClick' && onClose(!isOpen);
  };
  return (
    <div>
      <MaterialModal onClose={handleClose} open={isOpen} id={testId} className={styles.modal}>
        {renderedComponent()}
      </MaterialModal>
    </div>
  );
};

export default Modal;
