import { ActionType } from 'typesafe-actions';

import { AlertModalProps } from 'src/components/shared/ui/alert-modal/types';

import * as actions from './actions';

export interface State {
  configModal: ConfigModal;
}

export enum Contain {
  CONFIRM_MODAL = 'CONFIRM_MODAL',
  ALERT_MODAL = 'ALERT_MODAL',
  HEADING_FORM_MODAL = 'HEADING_FORM_MODAL',
  EMPLOYEE_FORM_MODAL = 'EMPLOYEE_FORM_MODAL',
  PREVIEW_MODAL = 'PREVIEW_MODAL',
  PDF_MODAL = 'PDF_MODAL',
}

export interface ConfigModal {
  showModal: boolean;
  contain: Contain | '';
  props: modalContentProps;
}

export type modalContentProps = AlertModalProps;

export type ActionsType = ActionType<typeof actions>;
