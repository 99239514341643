import { useQuery } from '@tanstack/react-query';

import { getQueryRequest } from 'src/config/api';

import { Category } from './types';

const getCategories = async () => await getQueryRequest<Category[]>('/category');

export const useCategoriesQuery = () => {
  return useQuery({
    queryKey: ['categories'],
    queryFn: () => getCategories(),
    retry: false,
    staleTime: 5 * 60000,
  });
};
