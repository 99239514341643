import { AxiosResponse } from 'axios';
import { UseQueryResult } from '@tanstack/react-query';

import { serverResponseType } from 'src/config/api';

const getDataFromQuery = <T>(
  query: UseQueryResult<AxiosResponse<serverResponseType<T>>>,
): T | null => {
  return query.data?.data?.data ?? null;
};

export default getDataFromQuery;
