import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { RootState } from 'src/redux/store';
import { AccessRoleType } from 'src/types';

import { PrivateRouteProps } from './types';

const PrivateRoute = ({
  privateRouteAccessTypes,
  redirectPath = '/not-allowed',
  children,
}: PrivateRouteProps): JSX.Element => {
  const { error, isLoading } = useSelector((state: RootState) => state.auth);

  if (isLoading) {
    return null;
  }

  const accessTypes = localStorage.getItem('accessTypes')
    ? localStorage
        .getItem('accessTypes')
        .split(',')
        .map((item) => item.trim())
    : [];

  const token = localStorage.getItem('token');
  const isActive = localStorage.getItem('isActive');

  if (
    !isActive ||
    !accessTypes.some((accessType) =>
      privateRouteAccessTypes.includes(accessType as AccessRoleType),
    ) ||
    error ||
    !accessTypes.length ||
    !token
  ) {
    return <Navigate to={redirectPath} />;
  }
  return children ? children : <Outlet />;
};

export default PrivateRoute;
