import React from 'react';
import { SvgIcon } from '@mui/material';

const SuccessIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="25"
        height="25"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 0.5C6.1 0.5 0.5 6.1 0.5 13C0.5 19.9 6.1 25.5 13 25.5C19.9 25.5 25.5 19.9 25.5 13C25.5 6.1 19.9 0.5 13 0.5ZM10.5 19.25L4.25 13L6.0125 11.2375L10.5 15.7125L19.9875 6.225L21.75 8L10.5 19.25Z"
          fill="#078122"
        />
      </svg>
    </SvgIcon>
  );
};

export default SuccessIcon;
