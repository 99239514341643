import React, { useEffect, useMemo, useState } from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';

import { OperationType } from 'src/services/headings/types';

import Preview from '../preview';
import { months, PdfProps } from './types';

const Pdf = (props: PdfProps) => {
  const { headingsProps, month, year, selectedRows, tableData, apiRef } = props;
  const [monthString, setMonthString] = useState('');

  useEffect(() => {
    months.forEach((item) => {
      if (month === item.value) {
        setMonthString(item.label);
      }
    });
  }, []);

  const dataToPrint = useMemo(() => {
    return selectedRows?.map((item) => {
      const selectedRowsCategory = item[1]?.map((rows) => {
        const { firstName, lastName, ...headings } = rows;
        const previewData = Object.entries(headings)
          .sort()
          .reduce((acc, heading) => {
            const headingFind = headingsProps?.find((item) => item._id === heading[0]);
            const categoryFind = tableData?.find((category) => item[0] === category.category.id);
            const headingCategoryFind = categoryFind.headings?.find(
              (headings) => headings.field === heading[0],
            );
            if (headingCategoryFind && headingFind && heading[1] !== 0) {
              const headingAcc = {
                header:
                  headingFind.operation === OperationType.SUM
                    ? `(+) ${headingFind.name} (${headingFind.currency})`
                    : `(-) ${headingFind.name} (${headingFind.currency})`,
                value: heading[1],
              };
              acc.push(headingAcc);
              return acc;
            }
            return acc;
          }, []);
        const categoryFind = tableData?.find((category) => item[0] === category.category.id);
        const lastNameDataOne = {
          header: 'Apellido',
          value: `${lastName}`,
        };
        const firstNameDataOne = {
          header: 'Nombre',
          value: `${firstName}`,
        };
        const toteArs = {
          header: 'Total ARS',
          value: rows.ARS,
        };
        const toteUy = {
          header: 'Total UY',
          value: rows.UYU,
        };
        const toteUsd = {
          header: 'Total USD',
          value: rows.USD,
        };
        const signature = {
          header: 'Firma',
          value: '',
        };

        previewData.unshift(firstNameDataOne);
        previewData.unshift(lastNameDataOne);
        toteUy.value ? previewData.push(toteUy) : null;
        toteArs.value ? previewData.push(toteArs) : null;
        toteUsd.value ? previewData.push(toteUsd) : null;
        previewData.push(signature);
        const arrayData = [categoryFind.category.name, previewData];
        return arrayData;
      });
      return selectedRowsCategory;
    });
  }, [apiRef, selectedRows]);

  const numComponentsPerPage = 3;

  const renderPages = () => {
    const data = dataToPrint?.map((payments) => {
      return payments.map((payment) => {
        const copyPayment = payment[1];
        payment[1] = <Preview data={copyPayment} key={copyPayment} split={true} />;
        return payment;
      });
    });

    const pages = [];

    data.map((cat) => {
      const totalPages = Math.ceil(cat.length / numComponentsPerPage);
      for (let i = 0; i < totalPages; i++) {
        const startIndex = i * numComponentsPerPage;
        const endIndex = startIndex + numComponentsPerPage;
        const componentsToRender = cat.slice(startIndex, endIndex);

        const page = (
          <Page key={`page_${i + 1}`}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px',
              }}
            >
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Text
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '10px',
                  }}
                >{`Liquidaciones ${monthString} ${year}`}</Text>
              </View>
              <Text
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '10px',
                }}
              >
                {cat[0][0]}
              </Text>
              {componentsToRender.map((payment, index) => {
                return (
                  <View key={index}>
                    <View
                      key={`component_${index + 1}`}
                      style={{ justifyContent: 'flex-start', padding: '10px' }}
                    >
                      <View
                        key={`component_${index + 1}`}
                        style={{
                          border: '2px solid #373867',
                          borderRadius: '5px',
                        }}
                      >
                        <Text
                          style={{
                            fontSize: '10px',
                            padding: '3px',
                            backgroundColor: '#373867',
                            color: '#FFFFFF',
                          }}
                        >{`Liquidación correspondiente al mes de ${monthString} ${year}`}</Text>
                        {payment[1]}
                      </View>
                    </View>
                  </View>
                );
              })}
            </View>
          </Page>
        );

        pages.push(page);
      }
    });

    return pages;
  };

  return <Document>{apiRef && renderPages().map((payment) => payment)}</Document>;
};

export default Pdf;
