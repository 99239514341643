export enum AppRoutes {
  LOGIN = '/login',
  HEADINGS = '/headings',
  EMPLOYEES = '/employees',
  DRAFTS = '/drafts',
  HISTORIES = '/histories',
  LIQUIDATIONS = '/liquidations',
  NOT_ALLOWED = '/not-allowed',
}

export enum RoleRoutes {
  ACCOUNTANCY_AD = '/admin-ad',
  ACCOUNTANCY_AL = '/admin-al',
}
