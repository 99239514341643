import Joi from 'joi';

export const employeeValidation = () => {
  return Joi.object({
    categories: Joi.array().min(1).required().messages({
      'any.required': 'Las categorías son requeridas',
      'array.empty': 'Las categorías son requeridas',
      'array.base': 'Las categorías deben ser un array',
      'array.includesRequiredUnknowns': 'Las categorías deben contener un array con categorías',
      'array.min': 'Debe contener al menos una Categoría',
    }),
  });
};
