import * as React from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Tab, Tabs } from '@mui/material';

import { navbarItems } from 'src/constants';
import { RoleRoutes } from 'src/interfaces/routes-types';
import { AccessRoleType } from 'src/types';

import styles from './navbar.module.css';

const Navbar = () => {
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const [value, setValue] = React.useState<number>(null);
  const lSAccessTypes = localStorage.getItem('accessTypes');
  const accessTypes = lSAccessTypes ? lSAccessTypes.split(',').map((item) => item.trim()) : [];
  const roleRoute = accessTypes.includes(AccessRoleType.ACCOUNTANCY_AL)
    ? RoleRoutes.ACCOUNTANCY_AL
    : RoleRoutes.ACCOUNTANCY_AD;

  useEffect(() => {
    {
      const navBarFiltered = navbarItems.filter((item) =>
        item.navAccessTypes.some((navAccessType) => accessTypes.includes(navAccessType)),
      );
      const itemsDisplayed = navBarFiltered.findIndex((route) => path.includes(route.path));
      setValue(itemsDisplayed);
    }
  }, [accessTypes, path]);

  const handleChange = (_: React.SyntheticEvent, value: number) => {
    setValue(value);
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const items = navbarItems?.map(
    (item) =>
      item?.navAccessTypes?.some((navAccessTypes) => accessTypes.includes(navAccessTypes)) && (
        <Tab
          key={item.name}
          label={item.name}
          onClick={() => handleNavigation(`${roleRoute}${item.path}`)}
          className={styles.tab}
        />
      ),
  );

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Tabs
        value={value || 0}
        onChange={handleChange}
        aria-label="basic tabs example"
        indicatorColor="secondary"
        className={styles.tabs}
      >
        {items}
      </Tabs>
    </Box>
  );
};

export default Navbar;
