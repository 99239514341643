import React from 'react';

import styles from '../../../../layout/header/header.module.css';

const HeaderLogo = (): JSX.Element => {
  return (
    <div className={styles.headerLogo}>
      <img src={`${process.env.PUBLIC_URL}/assets/images/headerLogo2.png`} />
    </div>
  );
};

export default HeaderLogo;
