import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import firebaseApp from 'src/helper/firebase';
import { RootState } from 'src/redux/store';

import Loading from '../shared/ui/loading';
import Header from './header';
import styles from './layout.module.css';

const Layout = (): JSX.Element => {
  const { isLoading } = useSelector((state: RootState) => state.auth);
  const accessTypes = useSelector((state: RootState) => state.auth.authUser.accessTypes);

  const authError = useSelector((state: RootState) => state.auth.authError);

  const handleLogout = async () => {
    try {
      await firebaseApp.auth().signOut();
    } catch (error) {
      console.error(error);
    }
  };

  authError && handleLogout();

  return (
    <div className={styles.container}>
      {!!accessTypes.length && <Header />}
      {isLoading && <Loading show={isLoading} />}
      <div className={styles.outlet}>
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
