import Joi from 'joi';

import { CurrencyType, OperationType } from './types';

export const headingValidation = () => {
  return Joi.object({
    name: Joi.string()
      .min(3)
      .max(30)
      .trim()
      .required()
      .regex(/^[A-Za-zñÑáéíóúü0-9]+(?: [A-Za-zñÑáéíóúü0-9]+)*$/)
      .messages({
        'string.base': 'El nombre debe ser alfanumérico',
        'string.empty': 'El nombre es requerido',
        'any.required': 'El nombre es requerido',
        'string.min': 'El nombre debe contener al menos 3 caracteres',
        'string.max': 'El nombre debe contener como máximo 30 caracteres',
        'string.pattern.base': 'El nombre no es válido',
      }),
    description: Joi.string().max(70).trim().allow('').messages({
      'string.base': 'La descripción debe ser un string',
      'string.max': 'La descripción debe contener como máximo 70 caracteres',
    }),
    onlyLiquidate: Joi.boolean().required().messages({
      'boolean.base': 'Solo liquidación debe ser booleano',
      'any.required': 'Solo liquidación es requerido',
      'boolean.empty': 'Solo liquidación es requerido',
    }),
    categories: Joi.array().min(1).required().messages({
      'any.required': 'Las categorías son requeridas',
      'array.empty': 'Las categorías son requeridas',
      'array.base': 'Las categorías deben ser un array',
      'array.includesRequiredUnknowns': 'Las categorías deben contener un array con categorías',
      'array.min': 'Debe contener al menos una categoría',
    }),
    operation: Joi.string().valid(OperationType.SUM, OperationType.REST).required().messages({
      'any.only': 'La operación debe ser SUM o REST',
      'string.empty': 'La operación es requerida',
      'any.required': 'La operación es requerida',
    }),
    currency: Joi.string()
      .valid(CurrencyType.USD, CurrencyType.ARS, CurrencyType.UY)
      .required()
      .messages({
        'any.only': 'La moneda debe ser USD, ARS o UY',
        'string.empty': 'La moneda es requerida',
        'any.required': 'La moneda es requerida',
      }),
    isActive: Joi.boolean().messages({
      'boolean.base': 'El estado debe ser un booleano',
    }),
  });
};
