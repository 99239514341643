import { Headers } from 'src/components/shared/ui/tables/standard-table/types';

import { CurrencyType, OperationType } from '../../../services/headings/types';

export const header: Headers[] = [
  { header: 'Nombre', key: 'name' },
  { header: 'Descripción', key: 'description' },
  { header: 'Divisa', key: 'currency' },
  { header: 'Operación', key: 'operation' },
  { header: 'Categorías', key: 'categories' },
];

export const currency = [
  { value: CurrencyType.ARS, label: 'ARS' },
  { value: CurrencyType.USD, label: 'USD' },
  { value: CurrencyType.UY, label: 'UY' },
];

export const operation = [
  { value: OperationType.REST, label: 'Resta' },
  { value: OperationType.SUM, label: 'Suma' },
];

export const onlyLiquidate = [
  { value: false, label: 'NO' },
  { value: true, label: 'SI' },
];
