import axios, { AxiosResponse } from 'axios';
import firebase from 'firebase/compat/app';
import { MutationFunction } from '@tanstack/react-query';

import firebaseApp from 'src/helper/firebase';
import { ErrorFormat, ErrorType } from 'src/interfaces/axios-types';

const token = localStorage.getItem('token');

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
    token: token,
  },
});

const responseBody = (response: AxiosResponse) => response.data;

const parseError = async (error) => {
  if (error.response?.status === 401) {
    const { config } = error;
    const user: firebase.User = firebaseApp.auth().currentUser;
    if (user) {
      const token = await user.getIdToken(true);
      config.headers['token'] = token;
      try {
        await api.request(config);
      } catch (error) {
        throw {
          message:
            'Su sesión ha expirado. Por favor recargue la página. Las modificaciones realizadas quedarán guardadas',
          errorType: ErrorType.NETWORK_ERROR,
        };
      }
    }
  }

  if (error.response.status === 403) {
    throw {
      message: error.response.data.message,
      errorType: ErrorType.AUTH_ERROR,
    };
  }
  throw {
    message: error.response.data.message,
    errorType: ErrorType.CLIENT_ERROR,
  };
};

export type serverResponseType<T> = { message: string; error: boolean; data: T };

export const getQueryRequest = async <T>(
  apiRoute: string,
): Promise<AxiosResponse<serverResponseType<T>, string>> => {
  const response = await api.get(apiRoute);
  return response;
};

// export const patchMutationRequest = async <T>(
//   apiRoute: string,
//   id: string,
// ): MutationFunction<unknown, void> => {
//   const response = await api.patch(`${apiRoute}/${id}`);

//   return response;
// };

export const getResourceRequest = <T>(apiRoute) =>
  api.get<T[]>(apiRoute).then(responseBody).catch<ErrorFormat>(parseError);

export const addResourceRequest = <T>(apiRoute, body: T) =>
  api.post<T>(apiRoute, body).then(responseBody).catch<ErrorFormat>(parseError);

export const editResourceRequest = <T>(apiRoute, options: { body: T; id: string }) =>
  api
    .put<T>(`${apiRoute}/${options.id}`, options.body)
    .then(responseBody)
    .catch<ErrorFormat>(parseError);

export const disableResourceRequest = <T>(apiRoute, id: string) =>
  api.patch<T>(`${apiRoute}/${id}`).then(responseBody).catch<ErrorFormat>(parseError);

export const deleteResourceRequest = <T>(apiRoute, id: string) =>
  api.delete<T>(`${apiRoute}/${id}`).then(responseBody).catch<ErrorFormat>(parseError);

export const patchResourceRequest = <T>(apiRoute, options: { body: T; id: string }) =>
  api
    .patch<T>(`${apiRoute}/${options.id}`, options.body)
    .then(responseBody)
    .catch<ErrorFormat>(parseError);

export const editMassivelyResourceRequest = <T>(apiRoute, body: T) =>
  api.put<T>(apiRoute, body).then(responseBody).catch<ErrorFormat>(parseError);

export const createResourceRequest = <T>(apiRoute) =>
  api.post<T>(apiRoute).then(responseBody).catch<ErrorFormat>(parseError);

export default api;
