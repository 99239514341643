import '@fontsource/roboto/400.css';
import { esES as esp } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { esES } from '@mui/x-data-grid';

export const themes = createTheme(
  {
    typography: {
      fontFamily: 'Roboto',
      fontSize: 15,
      button: {
        textTransform: 'none',
        fontWeight: 'normal',
      },
    },
    palette: {
      warning: {
        main: '#E8E8FD',
      },
      primary: {
        main: '#373867',
      },
      secondary: {
        main: '#3C9A70',
      },
      error: {
        main: '#FF0000',
      },
      info: {
        main: '#373867',
      },
    },
  },
  esES,
  esp,
);
