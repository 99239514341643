import React from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { AutocompleteChip, Button } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import Loading from 'src/components/shared/ui/loading';
import { useAppDispatch } from 'src/redux/store';
import { closeModal } from 'src/redux/ui/actions';
import { useCategoriesQuery } from 'src/services/categories/query';
import { useEmployeeCategoryMutation } from 'src/services/employees/mutation';
import { AppDispatch } from 'src/types';
import getDataFromQuery from 'src/utils/getDataFromQuery';

import styles from './employee-form.module.css';
import { EmployeeFormModalProps, FormValues } from './types';
import { employeeValidation } from './validations';

const EmployeeForm = (props: EmployeeFormModalProps) => {
  const dispatch: AppDispatch<null> = useAppDispatch();

  const { employee } = props;

  const categoriesQuery = useCategoriesQuery();
  const categoriesData = getDataFromQuery(categoriesQuery);

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<FormValues>({
    defaultValues: {
      categories: employee.categories.map((category) => {
        return {
          id: category._id,
          label: category.name,
        };
      }),
    },
    mode: 'onBlur',
    resolver: joiResolver(employeeValidation()),
  });

  const mutation = useEmployeeCategoryMutation();

  const onSubmit = async (data) => {
    const categoriesIds = data.categories.map((category) => category.id);

    const options = { id: employee._id, data: { categories: categoriesIds } };
    mutation.mutate(options);
  };

  if (categoriesQuery.isFetching || mutation.isPending) return <Loading show />;

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.title}>
          Editar categorias de {employee.user.firstName} {employee.user.lastName}.
        </div>
        <div className={styles.containerInputs}>
          <div className={styles.inputs}>
            <div className={styles.chip}>
              <AutocompleteChip
                control={control}
                name="categories"
                items={categoriesData.map((category) => {
                  return {
                    id: category._id,
                    label: category.name,
                  };
                })}
                label={'Categorías'}
                testId={'employee-categories-input'}
              />
            </div>
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            testId="cancel-employee-button"
            materialVariant={Variant.OUTLINED}
            label="Cancelar"
            onClick={() => dispatch(closeModal())}
          />
          <Button
            testId="submit-employee-button"
            materialVariant={Variant.CONTAINED}
            label="Confirmar"
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty}
          />
        </div>
      </form>
    </div>
  );
};

export default EmployeeForm;
