import React from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { TextField } from '@mui/material';

import { InputTextProps } from './types';

const TextInput = <TFormValues extends FieldValues>({
  name,
  control,
  defaultValue,
  fullWidth = true,
  showError = true,
  rules = undefined,
  ...props
}: InputTextProps<TFormValues>): JSX.Element => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, defaultValue, rules });

  return (
    <TextField
      {...field}
      {...props}
      fullWidth={fullWidth}
      helperText={showError && (error?.message ?? ' ')}
      error={showError && !!error?.message}
      data-testid={`${name}-field`}
      inputProps={{ ...props.inputProps }}
      InputProps={{ sx: { pl: '8px' } }}
    />
  );
};

export default TextInput;
