import React, { useEffect, useRef, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';

import { Button } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import HeaderLogo from 'src/components/shared/ui/icons/header-logo';
import firebaseApp from 'src/helper/firebase';
import { useAppDispatch } from 'src/redux/store';
import { closeModal, openModal } from 'src/redux/ui/actions';
import { Contain } from 'src/redux/ui/types';
import { AppDispatch } from 'src/types';

import Navbar from '../navbar';
import styles from './header.module.css';

const Header = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch<null> = useAppDispatch();
  const [displayMenu, setDisplayMenu] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef?.current &&
        buttonRef?.current &&
        !menuRef?.current?.contains(event.target) &&
        !buttonRef?.current?.contains(event.target) &&
        displayMenu
      ) {
        setDisplayMenu(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [displayMenu]);

  const handleLogout = async () => {
    try {
      navigate('/login');
      await firebaseApp.auth().signOut();
      dispatch(closeModal());
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <header className={styles.header}>
      {displayMenu && (
        <div className={styles.menu} ref={menuRef}>
          <a href="https://admin.radiumrocket.com">
            <span className={styles.radiumMenuSpan}>Radium </span>
            <span>Admin</span>
          </a>
          <a href="https://radiumrocket.com/">
            <span className={styles.radiumMenuSpan}>Radium </span>
            <span>Web</span>
          </a>
        </div>
      )}
      <div className={styles.container}>
        <div
          className={styles.brand}
          onClick={(event) => {
            event.stopPropagation();
            setDisplayMenu(!displayMenu);
          }}
          ref={buttonRef}
        >
          <HeaderLogo />
          <span>Radium</span>
          <span>Accountancy</span>
          {!displayMenu ? (
            <IoIosArrowDown className={styles.arrow} />
          ) : (
            <IoIosArrowUp className={styles.arrow} />
          )}
        </div>
        <nav className={styles.navbar}>
          <Navbar />
        </nav>
        <div className={styles.logout}>
          <Button
            materialVariant={Variant.OUTLINED}
            color="primary"
            label="Salir"
            endIcon={<LogoutIcon />}
            onClick={() =>
              dispatch(
                openModal(
                  {
                    action: () => handleLogout(),
                    title: 'Cerrar sesión',
                    text: '¿Desea cerrar sesión de Radium Accountancy?',
                  },
                  Contain.CONFIRM_MODAL,
                ),
              )
            }
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
