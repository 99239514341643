import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { AutocompleteChip, Button, RadioButton, TextInput } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import Dropdown from 'src/components/shared/ui/dropdown';
import { useCategoriesQuery } from 'src/services/categories/query';
import { usePostHeadingMutation, useUpdateHeadingMutation } from 'src/services/headings/mutation';
import { CurrencyType, OperationType } from 'src/services/headings/types';
import getDataFromQuery from 'src/utils/getDataFromQuery';

import { currency, onlyLiquidate, operation } from '../constants';
import styles from './heading-form.module.css';
import { FormValues, HeadingFormModalProps } from './types';
import { headingValidation } from './validations';

const HeadingForm = (props: HeadingFormModalProps) => {
  const { title, heading, onClose } = props;

  const updateHeadingMutation = useUpdateHeadingMutation();
  const postHeadingMutation = usePostHeadingMutation();

  const categoriesQuery = useCategoriesQuery();
  const categoriesData = getDataFromQuery(categoriesQuery) || [];

  const categories = useMemo(
    () =>
      categoriesData?.map((category) => {
        return {
          id: category._id,
          label: category?.name,
        };
      }),
    [categoriesData],
  );

  const { handleSubmit, control } = useForm<FormValues>({
    defaultValues: {
      name: heading?.name || '',
      description: heading?.description || '',
      onlyLiquidate: heading?.onlyLiquidate || false,
      categories:
        heading?.categories?.map((category) => {
          return {
            id: category._id,
            label: category.name,
          };
        }) || [],
      operation: heading?.operation || OperationType.REST,
      currency: heading?.currency || CurrencyType.ARS,
      isActive: heading?.isActive || true,
    },
    mode: 'onBlur',
    resolver: joiResolver(headingValidation()),
  });

  const onSubmit = async (data) => {
    const categoryIdsArr = data?.categories?.map((category) => category.id);

    if (heading?._id) {
      const options = { id: heading._id, data: { ...data, categories: categoryIdsArr } };
      const res = await updateHeadingMutation.mutateAsync(options);
      if (res.status < 400) {
        onClose();
      }
    } else {
      const dataToSend = {
        ...data,
        categories: categoryIdsArr,
      };
      const res = await postHeadingMutation.mutateAsync(dataToSend);
      if (res.status < 400) {
        onClose();
      }
    }
  };

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.title}>{title}</div>
        <div className={styles.containerInputs}>
          <div className={styles.firstContain}>
            <div className={styles.inputs}>
              <TextInput
                control={control}
                label="Nombre"
                name="name"
                type={'text'}
                error
                fullWidth
              />
              <Dropdown
                control={control}
                testId={'currency-dropdown'}
                label="Divisa"
                name="currency"
                options={currency}
                error
                fullWidth
              />
            </div>
            <div className={styles.inputs}>
              <TextInput
                control={control}
                label="Descripción"
                name="description"
                type={'text'}
                error
                fullWidth
                multiline
                rows={5}
                data-testid={'heading-input-description'}
              />
            </div>
          </div>
          <div className={styles.secondContainer}>
            <div className={styles.firstRadioContainer}>
              <h4>Operación</h4>
              <RadioButton
                testId={'operation-radio-button'}
                name={'operation'}
                label={'Operación'}
                options={operation}
                control={control}
                className={styles.radioButton}
              />
            </div>
            <div className={styles.secondRadioContainer}>
              <h4>Solo liquidación</h4>
              <RadioButton
                testId={'only-liquidate-radio-button'}
                name={'onlyLiquidate'}
                label={'Solo liquidación'}
                options={onlyLiquidate}
                control={control}
                className={styles.radioButton}
              />
            </div>
          </div>
        </div>
        <div className={styles.categories}>
          <AutocompleteChip
            control={control}
            name="categories"
            items={categories}
            label={'Categorías'}
            testId={'heading-categories-input'}
          />
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            testId="cancel-heading-button"
            materialVariant={Variant.OUTLINED}
            label="Cancelar"
            onClick={() => onClose()}
          />
          <Button
            testId="submit-heading-button"
            materialVariant={Variant.CONTAINED}
            label="Confirmar"
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </form>
    </div>
  );
};

export default HeadingForm;
