import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { RootState } from './redux/store';
export type AppDispatch<T> = ThunkDispatch<RootState, T, AnyAction>;

export enum LiquidationState {
  DRAFT = 'DRAFT',
  LIQUIDATION = 'LIQUIDATION',
  CLOSED = 'CLOSED',
}

export interface UserData {
  _id?: string;
  firebaseUid?: string;
  accessTypes: AccessRoleType[];
  email: string;
  dni?: number;
  phone?: number;
  address?: string;
  firstName: string;
  lastName: string;
  location?: string;
  birthDate?: Date;
  workedHours?: number;
  isActive: boolean;
  clockifyApiKey?: string;
  photoType?: string;
  operationalEmployee?: string;
  administrativeEmployee?: string;
}

export enum AccessRoleType {
  ACCOUNTANCY_AM = 'ACCOUNTANCY_AM',
  ACCOUNTANCY_AL = 'ACCOUNTANCY_AL',
  ACCOUNTANCY_AD = 'ACCOUNTANCY_AD',
}
