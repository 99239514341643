import React from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { RadioButtonProps } from './types';

const RadioButton = <Form extends FieldValues>(props: RadioButtonProps<Form>): JSX.Element => {
  const { control, name, options, testId, className } = props;
  const {
    field: { ref, value, onChange },
  } = useController({
    control,
    name,
  });

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div>
      <FormControl className={className}>
        <RadioGroup value={value}>
          {options.map((option) => (
            <FormControlLabel
              value={option.value}
              control={
                <Radio inputRef={ref} onChange={handleChange} data-testid={option['value']} />
              }
              label={<p>{option['label']}</p>}
              key={option['value'].toString()}
              data-testid={testId}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default RadioButton;
