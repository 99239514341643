import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LinearProgress } from '@mui/material';
import { useIsFetching } from '@tanstack/react-query';

import { Variant } from 'src/components/shared/ui/buttons/button/types';
import { closeModal } from 'src/redux/ui/actions';
import {
  useConfirmLiquidationMutation,
  useCreateLiquidationMutation,
} from 'src/services/liquidations/mutation';
import { useLiquidationQuery } from 'src/services/liquidations/query';
import { AppDispatch } from 'src/types';

import Button from '../buttons/button';
import SuccessIcon from '../icons/success';
import WarningIcon from '../icons/warning';
import styles from './modal.module.css';
import { AlertModalProps } from './types';

const AlertModal = (props: AlertModalProps) => {
  const dispatch: AppDispatch<null> = useDispatch();

  const { title, text, warning, success } = props;

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <h2>{title}</h2>
        {warning && <WarningIcon />}
        {success && <SuccessIcon />}
      </div>
      <div>
        <div>
          <p>{text}</p>
        </div>
      </div>
      <div className={styles.button}>
        <Button
          materialVariant={Variant.CONTAINED}
          color="primary"
          label="Cerrar"
          onClick={() => dispatch(closeModal())}
        />
      </div>
    </div>
  );
};

export default AlertModal;
